* {
  border-radius: 0 !important;
}

.home-header .popup{
  position: absolute;
  padding: 30px;
  max-width: 700px;
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  margin:0 auto;
  border-radius: 3px;
  box-shadow: 0px 0px 19px 5px rgba(0,0,0,0.19); 
  top: 20%;
  left: 30%; 
}
.home-header .overlay{
  width: 100vw;
  height:100vh;
  top:0;
  left:0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: rgba(49,49,49,0.8);
}

.popup .close-btn{
  position: absolute;
  background: #888;
  color: #000;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 20px;
  line-height: 20px;
}
.field{
  width: 100%;
  padding: 0.5rem 1rem;
  margin-bottom: 22px;
  background-color: rgba(230,230,230,0.6);
  font-size: 1.1em;
  outline: none;
  border: 2px solid rgba(0,0,0,0);
  transition: .3s;
}
.field:hover{
  background-color: rgba(0,0,0,0.1);
}
.field:focus{
  background-color: #fff;
  border: 2px solid rgba(30, 85, 250, 0.47);
}
.form h2{
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: #000;
  float: left;
}
.area{
  max-height: 150px;
}

.form .btn{
  width:100%;
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  background-color: #2ecc71;
  cursor: pointer;
  outline: none;
  border: none;
  transition: .3s;
  color:#fff;
}
.form .btn:hover{
  background-color: #27ae60;
}

/* end of modal popup */


/* home.js page css */
  .home-section {
     position: relative !important;
     padding-top:  150px !important;
     padding-bottom: 50px !important;
     box-sizing: border-box;
     margin: 0;
     padding: 0;
  }
  .home-content {
    text-align: left;
  }
  
.home-content .home-buttons{
    background-color: #e0aa3e;

}
.home-content h4{
  border-bottom: 5px solid #e0aa3e;
  padding-bottom: 10px;
  width: 450px;
}
  
  .heading {
    font-size: 2.4em !important;
    padding-left: 50px !important;
  }
  
  .heading-name {
    font-size: 2.5em !important;
    padding-left: 45px !important;
  }
  
  /* home about section */
  .home-about-section {
    position: relative;
    padding-bottom: 70px !important;
    padding-top: 70px !important;
  }
  .home-about-section .header{
    background-color: #f1f1f1;
    color: #000;
    padding: 50px;
  }
  .home-about {
    position: relative;
    padding-bottom: 70px !important;
    padding-top: 70px !important;
  }
  .home-about h1{
    padding: 10px;
    color: #e0aa3e;
  }
  .home-about h5{
    padding: 10px;
  }
  .home-about-img{
    width: 80%;
    height: 600px;
    border-radius: 20px !important;
    margin-bottom: 50px;
    filter: brightness(80%)
  } 
.home-about .home-about-row{
  padding: 50px;
}

.home-about-row{
  background-color: #f1f1f1;
  color: #000;
}

.home-about-row .home-about-details img{
    height: 450px;
}
  .details{
    display: flex;
    align-items: center;
    padding: 10px;
  }
  .details .part1 {
    background-color: #e0aa3e;
    padding: 5px;
    border-radius: 2px;
  }
  .details .details-icon{
    color: #e0aa3e;
    font-size: 30px;
    font-weight: bold;
  }
  .details p{
    margin-left: 5px;
    text-align: left;
    font-size: 1.1rem;
    display: inline-block;
  }

  /* home card section */
  .home-card {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    height: auto !important; 
  }
  
.home-card .home-card-view {
    /* box-shadow: 0 4px 5px 3px gray !important; */
    color: #000 !important;
    background-color: #fff !important;
    opacity: 0.9 !important;
    transition: all 0.5s ease 0s !important;
    height: 100% !important;
  }

  .home-card .home-card-view .btn{
    background-color: #e0aa3e !important;
    border: 0 !important;
    padding: 13px;
    font-size: 1.1em;
    margin: 20px;
  }

.home-card .home-card-view .icon-colour {
    color: #e0aa3e !important;
    font-size: 50px !important;
    text-align: left !important;
  }

.home-content .home-buttons{
    background-color: #e0aa3e !important;
    border: 0 !important;
    padding: 12px 15px;
    font-size: 1em;
    margin-top: 20px;
}

/* #the four steps */
.home-steps{
    max-width: 1100px;
    margin: auto;
    overflow: auto;
    padding: 0 2rem;
}
img{
  width:100%;
}
.home-steps .card{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap:2rem;
  background: #f1f1f1;
  margin-bottom: 2rem;
}

.home-steps .card h3{
  margin-bottom: 2rem;
  color: #000;
  border-bottom: 5px solid #e0aa3e;
  padding-bottom: 10px;
  width: 160px;
}
.home-steps .card p{
  color: #000;
  font-size: 18px;
  text-align: justify;
}
.home-steps .card img{
  height:400px;
}
.home-steps .card > div{
  padding: 2rem;
}


@media (max-width:700px){
  .home-steps .card{
      display: block;
  }
}