.services-section {
    position: relative !important;
    padding-top: 150px !important;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
.services-section .services-sector{
    padding: 20px;
   
}
.services-sector .sectors{
    border: 2px solid grey;
    border-radius: 5px !important;
}
.services-sector .sectors h5{
    padding: 10px;
}
.services-sector .sectors p{
    padding: 10px;
}
.services-section-2{
    width: 100%;
}
.services {
    position: relative;
    text-align: center;
    padding: 50px;
    align-items: center
}

.services img{
    width: 100%;
    height: auto;
    border-radius: 3px !important;
}

.services .service-content {
    position: absolute;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
    width: 50%;
    border: 1px solid black;
    border-radius: 5px !important;
    padding: 50px;
    align-items: left;
    background-color: #000;
    opacity: 0.8;
  }
.service-content h1{
}
.service-content p{
    font-size: 18px;
}
.service-content .service-buttons{
    background-color: #e0aa3e ;
    border: 0 !important;
    padding: 10px;
    align-items: left;
}


@media (max-width:700px){
    .services-section-2{
        width: 100%;
    }
    .services {
        position: relative;
        text-align: center;
        /* padding: 50px; */
        align-items: center
    }
    .services img{
        width: 100%;
        height: 100%;
        border-radius: 3px !important;
    } 
    .services .service-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        border: 1px solid black;
        border-radius: 5px !important;
        padding: 50px;
        align-items: left;
        background-color: #000;
        opacity: 0.8;
      }

}