.about-section {
    position: relative !important;
    padding-top: 150px !important;
    padding-bottom: 30px !important; 
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
.about-first{
  position: relative;
  margin: 0 auto;
  box-sizing: border-box;
  opacity: 0.7;
}
.about-first img{
  background:rgba(0,0,0,0.5);
  height: 500px;
}
.about-second{
  padding: 50px;

}
.about-second .description{
  padding: 20px;
}


.about-second .description h4{
  color: #e0aa3e;
}
.about-second .descriptions{
  padding:50px;
}
.about-second .descriptions h4 hr{
  width: 400px;
  height: 5px;
  background-color: #e0aa3e;
  margin: 0 auto;
  border: none;
  text-align: center;
}
.descriptions{
  color: #000;
  border: 1px solid;
  background-color: #fff;
}
.descriptions h1{
  padding: 20px;
}
.descriptions .words{
  padding: 50px;
  font-size: 20px;
  text-align: justify;
}
.descriptions .words img{
  height: 500px;
}
/* .about-first hr{
  width: 500px;
  height: 5px;
  background-color: #e0aa3e;
  margin: 0 auto;
  border: none;
  text-align: center;
}
.about-description hr{
  width: 100px;
  height: 5px;
  background-color: #e0aa3e;
  margin: 0 auto;
  border: none;
  align-items: left;
  text-align: left;
} */


.about-description .words{
  padding: 50px !important;
}
.about-description p{
  text-align: justify;
  font-size: 17px;
  line-height: normal;
}
.about-description img{
  height: 90%;
  width: 80%;
}
.about-first h2{
  padding: 50px;
}

.home-card {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    height: auto !important;
  }
  
  .home-card-view {
    box-shadow: 0 4px 5px 3px gray !important;
    color: #000 !important;
    background-color: #fff !important;
    opacity: 0.9 !important;
    transition: all 0.5s ease 0s !important;
    height: 100% !important;
  }
.home-card .home-card-view p{
  font-size: 1.1em;
}
.home-card .home-buttons{
  background-color: #d4af37;
  color: #fff;
}
.icon-colour {
    color: #ffff33  !important;
    margin: 15px;
  }

  /* teams section */
.teams-container{
  /* max-width: 1200px; */
  margin: auto;
  padding: 48px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.teams{
  margin:10px;
  padding: 22px;
  max-width: 80%;
  cursor: pointer;
  transition: 0.4s;
  box-sizing: border-box;
}
.teams:hover{
  background: #ddd;
}
.teams img{
  width:200px;
  height:200px;
} 
.name{
  padding: 12px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color:#000;
}
.design{
  font-style: italic;
  color: #888;
  font-size: 20px;
}
.about{
  margin: 20px 0;
  font-weight: lighter;
  color: #000;
  text-align: justify;
}
.social-links{
  margin: 14px;
}
.social-links:hover{
  transform: scale(1.5);
}
.social-links a{
  display: inline-block;
  color: #e0aa3e;
  font-size: 1.4em;
  margin-right: 5px;
}
.ourteam{
  background:#f5f5f5;
  border-radius: 2px;
}
.ourteam h1{
  padding: 20px;
  color: #4e4e4e;
}


/* responsive teams section */
@media screen and (max-width:600px){
  .teams{
    max-width: 100%;
  }
}